import { Divider, EmptyMessage } from '@/components';
import { ContentListLines } from '@/components/dataTable/components';
import { ReportListHeader } from '@/modules/move/components/reportListHeader/reportListItemHeader';
import { useMoveSheet } from '@/modules/move/hooks';
import { LIST_INITIAL_PAGE } from '@/shared/entities/constants/list';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Pagination } from 'vkit/lib/components';
import { Row } from 'vkit/lib/context';
import { ReportListItem } from '..';
import {
  MOVE_ERROR_TYPE,
  MOVE_SHEET_INITIAL_PAGE,
  MOVE_SHEET_PAGE_SIZE_DEFAUlT,
  MoveSheetParams,
} from '../../entities';

interface ReportListProps {
  moveId?: string;
  linesLoader?: number;
  onlyErrors?: 1;
}

export const ReportList: React.FC<ReportListProps> = ({ moveId, onlyErrors, linesLoader = 5 }) => {
  const [filters, setFilters] = useState<MoveSheetParams>({});

  const { isLoading, isEmptySheet, pagination, sheet, showPagination, showList } = useMoveSheet(filters);

  const handleChangePageSize = (value: number) => {
    setFilters({
      ...filters,
      pageSize: value,
    });
  };

  const handleChangePage = (value: number) => {
    setFilters({
      ...filters,
      page: value,
    });
  };

  const isOnlyErrors = useMemo(() => onlyErrors === 1, [onlyErrors]);

  const emptyMessage = useMemo(() => {
    return {
      title: isOnlyErrors ? 'Nenhum erro!' : 'Nenhuma linha processada!',
      message: isOnlyErrors
        ? 'Nenhum erro de validação foi encontrado'
        : 'Não encontramos nenhuma linha para processamento',
    };
  }, [isOnlyErrors]);

  const reportLines = () => (
    <>
      {isLoading && (
        <ContentListLines columns={[{}]} linesLoader={linesLoader} loading={isLoading} data={[]} />
      )}

      {showList && (
        <Row style={{ padding: 0, gap: '12px', marginBottom: '12px' }}>
          {sheet.map((line) => (
            <ReportListItem key={line.id} isOnlyError={isOnlyErrors} moveSheetNormalized={line} />
          ))}
        </Row>
      )}

      {isEmptySheet && (
        <EmptyMessage title={emptyMessage.title} description={emptyMessage.message} />
      )}

      {showPagination && (
        <>
          <Divider space={1} />

          <Row style={{ padding: '12px 0 0' }}>
            <Pagination
              page={pagination.page || LIST_INITIAL_PAGE}
              totalPages={pagination.totalPages}
              total={pagination?.total || sheet.length}
              pageSize={filters?.pageSize || MOVE_SHEET_PAGE_SIZE_DEFAUlT}
              onChangePage={handleChangePage}
              onChangePageSize={handleChangePageSize}
            />
          </Row>
        </>
      )}
    </>
  );

  useEffect(() => {
    if (!moveId) {
      return;
    }

    setFilters({
      fileId: moveId,
      page: MOVE_SHEET_INITIAL_PAGE,
      pageSize: MOVE_SHEET_PAGE_SIZE_DEFAUlT,
      sort: 'line',
      errorType: onlyErrors
        ? [MOVE_ERROR_TYPE.HTTP_ERROR, MOVE_ERROR_TYPE.VALIDATION_ERROR]
        : [],
    });
  }, [moveId, onlyErrors]);

  return isOnlyErrors ? (
    <Accordion
      full
      box
      title={<ReportListHeader errorType={MOVE_ERROR_TYPE.HTTP_ERROR} total={pagination.total} loading={isLoading} />}>
      {reportLines()}
    </Accordion>
  ) : (
    reportLines()
  );
};
