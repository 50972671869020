import { MOVE_ERROR_TYPE, MoveSheetNormalized } from '@/modules/move/entities';
import React from 'react';
import { Chip, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface ReportListItemHeaderProps {
  moveSheetNormalized: MoveSheetNormalized
}

export const ReportListItemHeader: React.FC<ReportListItemHeaderProps> = ({ moveSheetNormalized }) => {
  return (
    <Grid gap={24} alignItems='center'>
      <Grid gap={8}>
        <Text value='Linha:' size='medium' />
        <Text fontWeight='bold' value={moveSheetNormalized.line.toString()} size='medium' />
      </Grid>
      {moveSheetNormalized.hasError && !moveSheetNormalized.errorType?.includes(MOVE_ERROR_TYPE.REVIEW_ERROR) && (
        <Grid gap={8}>
          <Chip size='small' rounded color='danger' label='Contém erro(s)' />
        </Grid>
      )}
    </Grid>
  );
};
