import { MOVE_ERROR_TYPE } from '@/modules/move/entities';
import ICONS from '@/shareds/constants/icons';
import React, { useMemo } from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface ReportListItemHeaderProps {
  errorType: MOVE_ERROR_TYPE;
  total: number;
  loading?: boolean;
}

export const ReportListHeader: React.FC<ReportListItemHeaderProps> = ({ errorType, total, loading }) => {
  const isReviewError = errorType === MOVE_ERROR_TYPE.REVIEW_ERROR;

  const errorText = useMemo(() => {
    if (isReviewError) {
      if (total === 0) {
        return 'Não foi identificada nenhuma crítica';
      }

      if (total === 1) {
        return 'Foi identificada 1 crítica';
      }

      if (total > 1) {
        return `Foram identificadas ${total} críticas`;
      }
    } else {
      if (total === 0) {
        return 'Não foi identificado nenhum erro';
      }

      if (total === 1) {
        return 'Foi identificado 1 erro';
      }

      if (total > 1) {
        return `Foram identificados ${total} erros`;
      }
    }
  }, [isReviewError, total]);

  return (
    <Grid>
      <Chip
        size='small'
        icon={isReviewError ? ICONS.WARNING : ICONS.ALERT}
        color={isReviewError ? 'warning' : 'danger'}
        label={loading ? 'Carregando' : errorText}
      />
    </Grid>
  );
};
