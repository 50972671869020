import { BoxInfo } from '@/components';
import Divider from '@/components/divider';
import ReportReviewList from '@/modules/move/components/reportReviewList/reportReviewList';
import React, { useState } from 'react';
import { Text, Toggle } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import { ReportList } from '..';
import { MoveSheetParams } from '../../entities';

interface ReportProps {
  moveId?: string;
}

export const Report: React.FC<ReportProps> = ({ moveId }) => {
  const [onlyErrors, setOnlyErrors] = useState<MoveSheetParams['onlyErrors']>(1);

  return (
    <BoxInfo
      icon='trending-up-outline'
      title='Validação do arquivo'
      header={
        <Grid row margin='0 0 8px' alignItems='center' gap={12}>
          <Toggle
            checked={onlyErrors === 1}
            handleChange={() => {
              setOnlyErrors((currentValue) => (currentValue === 1 ? undefined : 1));
            }}
            small
          />
          <Text size='medium' margin='0 0 0 8px' value='Mostrar apenas erros e críticas' />
        </Grid>
      }>
      <ReportList onlyErrors={onlyErrors} moveId={moveId} />
      {Boolean(onlyErrors) && (
        <>
          <Divider noBorder />
          <ReportReviewList moveId={moveId} />
        </>
      )}
    </BoxInfo>
  );
};
