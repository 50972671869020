import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { useCallback, useRef, useState } from 'react';
import { Address } from '../../entities';
import { normalizeSearchedAddress } from '../../services';
import { viaCepService } from '../../services/viacep';

interface UseAddressFinderArgs {
  searchAddressCallback?: (addressFound: Address, zipcode: string) => void;
}

const useAddressFinder = ({ searchAddressCallback }: UseAddressFinderArgs) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [foundAddress, setFoundAddress] = useState<Address>({});
  const lastZipCode = useRef<string>('');

  const searchAddress = useCallback(
    async (zipcode: string) => {
      if (zipcode === lastZipCode.current) {
        return;
      }

      lastZipCode.current = zipcode;

      if (!zipcode || zipcode.length !== 8) {
        setFoundAddress({});
        searchAddressCallback?.({}, zipcode);
        return;
      }

      await tryOrCatchMessageError(
        async () => {
          const response = await viaCepService.find(zipcode);
          if (response.erro) {
            throw new Error('Address not found');
          }

          const foundAddress = normalizeSearchedAddress(response);
          setFoundAddress(foundAddress);
          searchAddressCallback?.(foundAddress, zipcode);
        },
        {
          messageErrorDefault:
            'Ops! Não foi possível encontrar o endereço com base no CEP informado.',
          tryBefore: () => {
            setLoading(true);
          },
          tryAfter: () => {
            setLoading(false);
          },
          catchError: () => {
            setFoundAddress({});
            searchAddressCallback?.({}, zipcode);
          }
        },
      );
    },
    [searchAddressCallback],
  );

  return {
    foundAddress,
    loading,
    searchAddress,
  };
};

export default useAddressFinder;
