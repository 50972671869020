import { BoxInfo } from '@/components';
import { BodyForm } from '@/components/formValidate';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import { MOVE_FILE_COLUMN_NAME } from '@/pages/moves/shareds/constants';
import { ADDRESS, ADDRESS_NAME, Address, Views } from '@/shared/modules/address';
import React, { useMemo, useRef, useState } from 'react';
import { Button } from 'vkit/lib/components';

interface AddressFormProps extends BodyForm {
  dataToCopy?: BeneficiaryToMove;
  updateBeneficiaryToMove: (beneficiaryToMove: BeneficiaryToMove) => void;
}

const AddressForm: React.FC<AddressFormProps> = ({
  dataToCopy,
  onChange,
  onBlur,
  useErrors,
  useValues,
  updateBeneficiaryToMove,
}) => {
  /**
   *
   * Estas "traduções abaixo só estarão aqui até a refatoração da criação de movimentações unitárias"
   * após a refatoração do componente de criação de movimentação (criação de beneficiário) esta lógica de tradução
   * estará separada em uma camada dedicada a criação do objeto a ser inserido na planilha.
   */
  const addressValues = {
    [ADDRESS_NAME.ZIPCODE]: useValues?.[MOVE_FILE_COLUMN_NAME.CEP] || '',
    [ADDRESS_NAME.LOCATION]: useValues?.[MOVE_FILE_COLUMN_NAME.LOGRADOURO] || '',
    [ADDRESS_NAME.NUMBER]: useValues?.[MOVE_FILE_COLUMN_NAME.NUMERO] || '',
    [ADDRESS_NAME.NEIGHBORHOOD]: useValues?.[MOVE_FILE_COLUMN_NAME.BAIRRO] || '',
    [ADDRESS_NAME.CITY]: useValues?.[MOVE_FILE_COLUMN_NAME.CIDADE] || '',
    [ADDRESS_NAME.STATE_ABBR]: useValues?.[MOVE_FILE_COLUMN_NAME.UF] || '',
    [ADDRESS_NAME.COMPLEMENT]: useValues?.[MOVE_FILE_COLUMN_NAME.COMPLEMENTO] || '',
  } as Address;

  const addressErrors = {
    [ADDRESS_NAME.ZIPCODE]: useErrors?.[MOVE_FILE_COLUMN_NAME.CEP],
    [ADDRESS_NAME.LOCATION]: useErrors?.[MOVE_FILE_COLUMN_NAME.LOGRADOURO],
    [ADDRESS_NAME.NUMBER]: useErrors?.[MOVE_FILE_COLUMN_NAME.NUMERO],
    [ADDRESS_NAME.NEIGHBORHOOD]: useErrors?.[MOVE_FILE_COLUMN_NAME.BAIRRO],
    [ADDRESS_NAME.CITY]: useErrors?.[MOVE_FILE_COLUMN_NAME.CIDADE],
    [ADDRESS_NAME.STATE_ABBR]: useErrors?.[MOVE_FILE_COLUMN_NAME.UF],
    [ADDRESS_NAME.COMPLEMENT]: useErrors?.[MOVE_FILE_COLUMN_NAME.COMPLEMENTO],
  };

  const initialValues = useRef(addressValues);
  const isInitialValuesEmpty = useMemo(
    () => Object.values(initialValues.current).every((value) => !value),
    [],
  );

  const [disabled, setDisabled] = useState<Partial<Record<ADDRESS, boolean>>>({
    [ADDRESS.CITY]: isInitialValuesEmpty || Boolean(initialValues.current.city),
    [ADDRESS.NEIGHBORHOOD]: isInitialValuesEmpty || Boolean(initialValues.current.neighborhood),
    [ADDRESS.LOCATION]: isInitialValuesEmpty || Boolean(initialValues.current.location),
    [ADDRESS.STATE_ABBR]: isInitialValuesEmpty || Boolean(initialValues.current.state_abbr),
    [ADDRESS.NUMBER]: isInitialValuesEmpty || Boolean(initialValues.current.number),
    [ADDRESS.COMPLEMENT]: isInitialValuesEmpty || Boolean(initialValues.current.complement),
  });
  
  const onChangeAddress = (keyOrObject: keyof Address | Record<ADDRESS, string>, value: string) => {
    if (typeof keyOrObject === 'string') {
      const translatedKey = {
        [ADDRESS_NAME.ZIPCODE]: MOVE_FILE_COLUMN_NAME.CEP,
        [ADDRESS_NAME.LOCATION]: MOVE_FILE_COLUMN_NAME.LOGRADOURO,
        [ADDRESS_NAME.NUMBER]: MOVE_FILE_COLUMN_NAME.NUMERO,
        [ADDRESS_NAME.NEIGHBORHOOD]: MOVE_FILE_COLUMN_NAME.BAIRRO,
        [ADDRESS_NAME.CITY]: MOVE_FILE_COLUMN_NAME.CIDADE,
        [ADDRESS_NAME.STATE_ABBR]: MOVE_FILE_COLUMN_NAME.UF,
        [ADDRESS_NAME.COMPLEMENT]: MOVE_FILE_COLUMN_NAME.COMPLEMENTO,
      }[keyOrObject];
      onChange(translatedKey, value);
      return;
    }

    updateBeneficiaryToMove({
      [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: keyOrObject[ADDRESS.LOCATION],
      [MOVE_FILE_COLUMN_NAME.NUMERO]: keyOrObject[ADDRESS.NUMBER],
      [MOVE_FILE_COLUMN_NAME.BAIRRO]: keyOrObject[ADDRESS.NEIGHBORHOOD],
      [MOVE_FILE_COLUMN_NAME.CIDADE]: keyOrObject[ADDRESS.CITY],
      [MOVE_FILE_COLUMN_NAME.UF]: keyOrObject[ADDRESS.STATE_ABBR],
      [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: keyOrObject[ADDRESS.COMPLEMENT],
    });
  };

  const useInfoBeneficiaryHolder = () => {
    updateBeneficiaryToMove({
      [MOVE_FILE_COLUMN_NAME.CEP]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.CEP],
      [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.LOGRADOURO],
      [MOVE_FILE_COLUMN_NAME.NUMERO]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.NUMERO],
      [MOVE_FILE_COLUMN_NAME.BAIRRO]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.BAIRRO],
      [MOVE_FILE_COLUMN_NAME.CIDADE]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.CIDADE],
      [MOVE_FILE_COLUMN_NAME.UF]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.UF],
      [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: dataToCopy?.[MOVE_FILE_COLUMN_NAME.COMPLEMENTO],
    });

    setDisabled({
      [ADDRESS.CITY]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.CIDADE]),
      [ADDRESS.NEIGHBORHOOD]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.BAIRRO]),
      [ADDRESS.LOCATION]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.LOGRADOURO]),
      [ADDRESS.STATE_ABBR]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.UF]),
      [ADDRESS.NUMBER]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.NUMERO]),
      [ADDRESS.COMPLEMENT]: Boolean(dataToCopy?.[MOVE_FILE_COLUMN_NAME.COMPLEMENTO]),
    });
  };

  return (
    <BoxInfo
      margin={0}
      title='Endereço'
      icon='pin-outline'
      header={
        dataToCopy?.[MOVE_FILE_COLUMN_NAME.CEP] ? (
          <Button label='Copiar endereço do titular' solo onClick={useInfoBeneficiaryHolder} />
        ) : null
      }>
      <Views.Form
        values={addressValues}
        useErrors={addressErrors}
        onBlur={onBlur}
        onChange={onChangeAddress}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    </BoxInfo>
  );
};

export default AddressForm;
