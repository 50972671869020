import { useMoveSheet } from '@/modules/move';
import { ReportListHeader } from '@/modules/move/components/reportListHeader/reportListItemHeader';
import {
  MOVE_ERROR_TYPE,
  MOVE_SHEET_INITIAL_PAGE,
  MOVE_SHEET_PAGE_SIZE_DEFAUlT,
  MoveSheetParams,
} from '@/modules/move/entities';
import { maskText } from '@/utils/format';
import React, { useEffect, useState } from 'react';
import { Accordion, DataTable } from 'vkit/lib/components';

interface ReportReviewListProps {
  moveId?: string;
  linesLoader?: number;
}

const ReportReviewList: React.FC<ReportReviewListProps> = ({ moveId, linesLoader }) => {
  const [filters, setFilters] = useState<MoveSheetParams>({});
  const { isLoading, pagination, sheet, showPagination } = useMoveSheet(filters);

  useEffect(() => {
    if (!moveId) {
      return;
    }
    setFilters({
      fileId: moveId,
      page: MOVE_SHEET_INITIAL_PAGE,
      pageSize: MOVE_SHEET_PAGE_SIZE_DEFAUlT,
      sort: 'line',
      errorType: [MOVE_ERROR_TYPE.REVIEW_ERROR],
    });
  }, [moveId]);

  return (
    <Accordion
      full
      box
      title={
        <ReportListHeader errorType={MOVE_ERROR_TYPE.REVIEW_ERROR} total={pagination.total} loading={isLoading} />
      }>
      <DataTable
        elevation={0}
        isLoading={isLoading}
        loadingLinesNumber={linesLoader}
        data={sheet}
        columns={[
          {
            name: 'Linha',
            path: 'line',
          },
          {
            name: 'Nome',
            path: 'review.beneficiaryName',
          },
          {
            name: 'CPF',
            path: 'review.cpf',
            format: ({ review }) => review.cpf && maskText(review.cpf, '999.999.999-99')
          },
          {
            name: 'Crítica(s)',
            path: 'review.errors',
          },
        ]}
        page={pagination.page}
        pageSize={pagination.pageSize}
        total={pagination.total}
        totalPages={pagination.totalPages}
        onChangePage={(page) => setFilters(() => ({ ...filters, page }))}
        onChangePageSize={(pageSize) => setFilters(() => ({ ...filters, pageSize }))}
        showPagination={showPagination}
      />
    </Accordion>
  );
};

export default ReportReviewList;
