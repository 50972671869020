import { MOVE_SHEET_COLUMN_LABEL } from '@/modules/move/entities/constants/moveSheetColumn';
import {
  translateSheetValues,
  translateValidationMessage,
} from '@/modules/move/utils/translations';
import { safeJsonParse } from '@/utils/validations/safeJsonParse';
import { get } from 'lodash';
import {
  MoveSheet,
  MoveSheetMessages,
  MoveSheetMessagesContentDataError,
  MoveSheetNormalized,
} from '../entities';
import { MoveSheetColumnKey } from '../entities/types/moveSheetColumn';

export const getErrorMessage = (
  messages: MoveSheetMessages[],
): MoveSheetMessagesContentDataError[] => {
  const json = JSON.stringify(
    messages
      .filter((message) => message.type === 'error')
      .map((message) => get(message, 'content.data.error', '')),
    null,
    4,
  );
  return JSON.parse(json)[0];
};

export const getContentData = (content: string): { [key in MoveSheetColumnKey]: string | null } => {
  return JSON.parse(content);
};

export const normalizeSheetLine = (sheetLine: MoveSheet): MoveSheetNormalized => {
  const { line, id, error, messages, content, errorType } = sheetLine;
  let listContent = undefined;
  let messageWarning: string | undefined = '';
  const data = getContentData(content);

  if (messages) {
    const errors = getErrorMessage(messages);
    messageWarning = errors?.find(error => error.variable === 'holder')?.messages.error;

    listContent = Object.entries(data)
      .filter(([ key ]) => key !== 'tipoMovimentacao')
      .map(([ key, value ]) => {
        const column = MOVE_SHEET_COLUMN_LABEL[key as MoveSheetColumnKey];
        const convertedValue = translateSheetValues(key, value);
        const parseErrorKey = safeJsonParse(error);
        const avaliableErrors = errors?.length ? errors : parseErrorKey;

        const findError = !avaliableErrors
          ? undefined
          : avaliableErrors.find(
            (error: MoveSheetMessagesContentDataError) => error.variable === key,
          );

        const messages = !findError
          ? []
          : Object.entries(findError.messages).map(([ errorKey, errorMessage ]) => ({
            key: errorKey,
            value: translateValidationMessage(
              errorKey,
              column,
              convertedValue,
              errorMessage as string,
            ),
          }));

        return {
          column,
          value: convertedValue,
          hasError: !!findError,
          messages,
        };
      });
  }

  const reviewMessage = messages?.find((message) => message.content.data.error?.length > 0);

  return {
    line,
    id,
    hasError: Boolean(error),
    errorType,
    content: listContent,
    messageWarning,
    review: {
      beneficiaryName: data.nome,
      cpf: data.cpf,
      errors: get(reviewMessage, 'content.data.error', []).map((message: MoveSheetMessagesContentDataError) => message.messages.error),
    }
  };
};
