import { useMoveSheetLine } from '@/modules/move/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Text } from 'vkit/lib/components';
import { ReportListItemContent, ReportListItemHeader } from '..';
import { MoveSheetNormalized } from '../../entities';

interface ReportListItemProps {
  isOnlyError: boolean;
  moveSheetNormalized: MoveSheetNormalized;
}

export const ReportListItem: React.FC<ReportListItemProps> = ({ isOnlyError, moveSheetNormalized }) => {
  const [isExpanded, setExpanded] = useState<boolean>(isOnlyError);
  const { loadMoveSheetLine, isLoading, sheetLine } = useMoveSheetLine({
    rowId: moveSheetNormalized.id,
  });

  const handleExpanded = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const showContent = useMemo(() => {
    if (moveSheetNormalized.content) {
      return moveSheetNormalized.content;
    }
    return sheetLine?.content;
  }, [moveSheetNormalized.content, sheetLine]);

  useEffect(() => {
    if (!isExpanded || moveSheetNormalized.content || !moveSheetNormalized.id) {
      return;
    }
    loadMoveSheetLine(moveSheetNormalized.id);
  }, [isExpanded, moveSheetNormalized.content, moveSheetNormalized.id, loadMoveSheetLine]);

  return (
    <Accordion
      full
      box
      expanded={isExpanded}
      handleExpanded={handleExpanded}
      title={<ReportListItemHeader moveSheetNormalized={moveSheetNormalized} />}>
      {
        moveSheetNormalized?.messageWarning && (
          <Text
            value={moveSheetNormalized.messageWarning}
            icon='alert-triangle-outline'
            iconColor='warning'
            color='warning'
            applyBaseColor
            padding='8px'
            rounded
            size='medium'
          />
        )
      }

      {
        (!moveSheetNormalized?.messageWarning || Number(showContent?.length) > 0) && (
          <ReportListItemContent
            isLoading={isLoading}
            sheetLineId={moveSheetNormalized.id}
            content={showContent}
            showOnlyErrors={isOnlyError}
          />
        )
      }
    </Accordion>
  );
};
