import { BaseListResponse } from '@/shared/entities';
import { Move } from '..';
import { MoveType } from '../types/move';
import { MoveSheetColumn } from './moveSheetColumn';

export enum MOVE_ERROR_TYPE {
  HTTP_ERROR = 'http_error',
  REVIEW_ERROR = 'review_error',
  VALIDATION_ERROR = 'validation_error',
}

export interface MoveSheetParams {
  onlyErrors?: 1;
  fileId?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  errorType?: MOVE_ERROR_TYPE[];
}

export interface MoveSheetMessagesRow {
  createdAt: string;
  messageId: string;
  rowId: string;
  updatedAt: string;
}

export interface MoveSheetMessagesContentDataError {
  variable: string;
  messages: Record<string, string>;
  type?: MOVE_ERROR_TYPE;
  beneficiaryName?: string;
}

export interface MoveSheetMessagesContentDataMessageMetaData {
  attachments: [];
  company: string;
  companyId: string;
  countBeneficiaries: number;
  type: MoveType;
  user: string;
  userId: string;
}

export interface MoveSheetMessagesContentDataMessageUploadInfos {
  idUpload: string;
  uploadAt: string;
  nomeArquivo: string;
  linhaArquivo: number;
  linkDownload: string;
  portalDestino: string;
  extensaoArquivo: string;
}

export interface MoveSheetMessagesContentDataMessage {
  data: MoveSheetColumn;
  metaData: MoveSheetMessagesContentDataMessageMetaData;
  uploadInfos: MoveSheetMessagesContentDataMessageUploadInfos;
}

export interface MoveSheetMessagesContentData {
  error: MoveSheetMessagesContentDataError[];
  fileLineNumber: number;
  message: MoveSheetMessagesContentDataMessage;
  processedStatus: string;
  status: string;
}

export interface MoveSheetMessagesContent {
  data: Record<string, MoveSheetMessagesContentData>;
}

export interface MoveSheetMessages {
  RowMessageModel: MoveSheetMessagesRow;
  content: any;
  createdAt: string;
  id: string;
  topic: string;
  type: 'error' | 'success';
  updatedAt: string;
}

export interface MoveSheet {
  beneficiaryStatus: string;
  content: string;
  createdAt: string;
  error: string | null;
  errorType: MOVE_ERROR_TYPE;
  fileId: string;
  id: string;
  lifeStatus: string;
  line: number;
  updatedAt: string | null;
  messages?: MoveSheetMessages[];
  file?: Move;
}

export interface MoveSheetResponse extends BaseListResponse<MoveSheet> {}

export interface MoveSheetNormalizedContentMessages {
  key: string;
  value: string;
}

export interface MoveSheetNormalizedContent {
  column?: string;
  value?: string | number | null;
  hasError: boolean;
  messages: MoveSheetNormalizedContentMessages[];
}

export interface MoveSheetNormalized {
  id: string;
  line: number;
  hasError: boolean;
  errorType?: MOVE_ERROR_TYPE;
  content?: MoveSheetNormalizedContent[];
  messageWarning?: string;
  review?: {
    cpf: string | null;
    beneficiaryName: string | null;
    errors: string[];
  }
}

export interface MoveSheetNormalizedResponse extends BaseListResponse<MoveSheetNormalized> {}
